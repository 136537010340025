import * as React from 'react';
import ReactMapboxGl, {Feature, Layer, Popup} from 'react-mapbox-gl';


const mapMarkers = [
    {
        id: 1,
        town: 'Hallstatt',
        street: 'Seestraße 148',
        plzTown: 'A – 4830 Hallstatt',
        lng: 13.647121,
        lat: 47.558726,
        position: [13.647121, 47.558726]
    },
    {
        id: 2,
        town: 'Graz',
        street: 'Lindweg 14/3',
        plzTown: 'A – 8010 Graz',
        lng: 15.439838,
        lat: 47.085898,
        position: [15.439838, 47.085898]
    }
];
const mapCenter = [
    mapMarkers.reduce((a, b) => (a.lng + b.lng)) / mapMarkers.length,
    mapMarkers.reduce((a, b) => (a.lat + b.lat)) / mapMarkers.length

];

const hasWindow = typeof window !== 'undefined' && window
let Map = false;

if (hasWindow) {
    Map = ReactMapboxGl({accessToken: `${process.env.GATSBY_MAPBOX_API_TOKEN}`})
}

export default class KerschbaumerMap extends React.Component {
    state = {
        fitBounds: undefined,
        center: mapCenter,
        zoom: [11],
        marker: undefined,
        markers: mapMarkers
    };


    onDrag = () => {
        if (this.state.marker) {
            this.setState({marker: undefined});
        }
    };

    onToggleHover(cursor, {map}) {
        map.getCanvas().style.cursor = cursor;
    }

    markerClick = (marker, {feature}) => {
        this.setState({
            center: feature.geometry.coordinates,
            zoom: [12],
            marker
        });
    };

    onStyleLoad = (map) => {
        const {onStyleLoad} = this.props;
        return onStyleLoad && onStyleLoad(map);
    };


    render() {
        const {fitBounds, center, zoom, marker, markers} = this.state;

        const mapBounds = markers.map(marker => ([marker.lng, marker.lat]));
        const mapStyle = 'mapbox://styles/mapbox/light-v9';


        return (Map &&
            <Map
                className={'w-full my-16 h-screen'}
                style={mapStyle}
                onStyleLoad={this.onStyleLoad}
                fitBounds={fitBounds}
                center={center}
                fitBounds={mapBounds}
                fitBoundsOptions={{padding: 100}}
                zoom={zoom}
                onDrag={this.onDrag}
                containerStyle={{flex: 1}}
                flyToOptions={{speed: 0.8}}
            >
                <Layer type="symbol" id="marker"
                    // layout={layoutLayer} images={images}
                       type="symbol"
                       id="marker"
                       layout={{'icon-image': 'marker-15'}}
                >
                    {markers.map(marker => (
                        <Feature
                            coordinates={marker.position}
                            key={marker.id}
                            onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
                            onMouseLeave={this.onToggleHover.bind(this, '')}
                            onClick={this.markerClick.bind(this, marker)}
                        />
                    ))}
                </Layer>
                {marker && (
                    <Popup key={marker.id} coordinates={marker.position}>
                        <div className={'p-4'}>
                            <div className={'text-xl font-bold'}>Filiale {marker.town}</div>
                            <div>
                                Architektin DI<br/>
                                Astrid Wildner-Kerschbaumer<br/>
                                {marker.street}<br/>
                                {marker.plzTown}
                            </div>
                        </div>
                    </Popup>
                )}
            </Map>
        );
    }
}
