import React from "react"
import {graphql, Link} from "gatsby"
import useSiteTreeContext from "silverstripe-gatsby-helpers/lib/hooks/useSiteTreeContext"
import Layout from "../Page"
import SEOTags from "../../components/SEOTags"
import Breadcrumbs from "../../components/Breadcrumbs"
import classnames from 'classnames';
import '@browniebroke/gatsby-image-gallery/dist/style.css'
import Gallery from "@browniebroke/gatsby-image-gallery";
import KerschbaumerMap from "../../components/Map"

const Page = ({data: {silverStripeDataObject, allSilverStripeDataObject}}) => {
    const {title, content} = silverStripeDataObject.SilverStripeSiteTree;

    const {getChildren, isLevel, getMenu} = useSiteTreeContext();
    const children = getChildren();
    const isLevel2 = isLevel(2);
    const hasSubnav = isLevel(2) || !!children.length;
    const navItems = isLevel2 ? getMenu(2) : children;

    const projects = allSilverStripeDataObject.edges
    let fullsize = [];
    let thumbs = [];

    projects
        .sort((a, b) => {
            //sort by project date, fall back to created
            const aDate = a.node.NetwerkstattImageGalleryPage.projectDate || a.node.created;
            const bDate = b.node.NetwerkstattImageGalleryPage.projectDate || b.node.created;

            return new Date(bDate) - new Date(aDate);
        })
        .forEach(project => {
            const galleryItems = project.node.NetwerkstattImageGalleryPage.ImageGalleryItems;
            const extraPreviewPic = project.node.NetwerkstattImageGalleryPage.Picture;

            const firstGalleryPic = galleryItems.sort((a, b) =>
                a.NetwerkstattImageGalleryItem.sortOrder - b.NetwerkstattImageGalleryItem.sortOrder
            )[0].NetwerkstattImageGalleryItem;

            const previewPic = extraPreviewPic || firstGalleryPic;

            if (previewPic.Image) {
                fullsize.push(previewPic.Image.imageFile.full.fluid.src);
                thumbs.push(previewPic.Image.imageFile.thumb.fluid);
            }

        });



    return (
        <Layout>
            <SEOTags pageTitle={title}/>

            <div className={`content ${hasSubnav ? 'hasSidebar' : ''}`}>
                <div className="main">
                    {isLevel2 &&
                    <Breadcrumbs/>
                    }
                    <h1 className="font-bold text-2xl mb-4">{title}</h1>

                    <div className="font-serif htmltext" dangerouslySetInnerHTML={{__html: content}}/>

                    <div className="px-3">
                        <Gallery images={fullsize} thumbs={thumbs} colWidth={100 / 3} mdColWidth={20} />
                    </div>
                    <KerschbaumerMap />
                </div>
                {hasSubnav &&
                <div className="sidebar">
                    <h2>In this section</h2>
                    <ul>
                        {navItems.map(child => (
                            <li key={child.id} className={classnames({
                                current: child.isCurrent,
                            })}>
                                <Link to={child.link}>{child.SilverStripeSiteTree.title}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                }
            </div>
        </Layout>
    );
};

export default Page

export const pageQuery = graphql`
    query ($link: String!) {
        silverStripeDataObject(link: {eq: $link}) {
            SilverStripeSiteTree {
                title
                content
            }
        }
        allSilverStripeDataObject(filter: {className: {eq: "Netwerkstatt__AstridWildner__Page__ImageGalleryPage"}}) {
            edges {
                node {
                    link
                    created
                    NetwerkstattImageGalleryPage {
                        projectDate
                        Picture {
                            imageFile {
                                absolutePath
                                childImageSharp {
                                    fluid(fit: COVER, maxHeight: 100, maxWidth: 200) {
                                        base64
                                        tracedSVG
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        sizes
                                        originalImg
                                        originalName
                                        presentationWidth
                                        presentationHeight
                                    }
                                }
                            }
                            id
                        }
                        ImageGalleryItems {
                            NetwerkstattImageGalleryItem {
                                Image {
                                    imageFile {
                                        thumb: childImageSharp {
                                            fluid(maxWidth: 270, maxHeight: 270) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                        full: childImageSharp {
                                            fluid(
                                                maxWidth: 1024
                                                quality: 85
                                                srcSetBreakpoints: [576, 768, 992, 1200]
                                            ) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }

                                }
                                sortOrder
                            }
                        }
                    }

                }
            }
        }
    }


`;